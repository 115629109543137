import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlay} from '@fortawesome/free-solid-svg-icons'

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hovered: undefined,
            scrolled: props.scrolled
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scrolled !== this.props.scrolled) {
            this.setState({scrolled: this.props.scrolled});
        }
    }

    onHover = (index) => {
        this.setState({hovered: index});
    }

    render() {
        return (
            <div className={'top-0 w-full h-28 pt-8'}>
                <div className={'w-screen h-full flex items-center justify-center md:justify-start'}>
                    <div
                        className={'w-64 h-full'}
                        style={{
                            backgroundImage: 'url(/assets/images/logo.png)',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    ></div>
                    <div className={'items-center pl-20 hidden md:flex'}>
                        <button
                            onMouseLeave={() => {
                                this.onHover(undefined)
                            }}
                            onMouseEnter={() => {
                                this.onHover(0)
                            }}>
                            <div
                                className={`${this.state.hovered === 0 ? `${this.state.scrolled ? 'text-black/90' : 'text-white/90'} border-b-2 border-main-50` : `${this.state.scrolled ? 'text-black/75' : 'text-white/75'} border-b-2 border-main-50/0`} transition-all duration-300 px-8 py-4 font-medium flex space-x-4 items-center`}>
                                <div>Головна</div>
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                this.props.play()
                            }}
                            onMouseLeave={() => {
                                this.onHover(undefined)
                            }}
                            onMouseEnter={() => {
                                this.onHover(1)
                            }}>
                            <div
                                className={`${this.state.hovered === 1 ? `${this.state.scrolled ? 'text-black/90' : 'text-white/90'} border-b-2 border-main-50` : `${this.state.scrolled ? 'text-black/75' : 'text-white/75'} border-b-2 border-main-50/0`} transition-all duration-300 px-8 py-4 font-medium flex space-x-4 items-center`}>
                                <div>Як почати грати</div>
                                <FontAwesomeIcon className={'text-xl'} icon={faCirclePlay}/>
                            </div>
                        </button>
                    </div>
                    <div className={'w-1/3'}></div>
                </div>
            </div>
        );
    }
}

export default Header;