import React, {Component} from 'react';
import Header from "./Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlay} from "@fortawesome/free-solid-svg-icons";
import Carousel from "./Carousel";

class Main extends Component {
    constructor() {
        super();

        this.playRef = React.createRef();

        this.state = {
            scrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (window.scrollY > 0 && !this.state.scrolled) {
            this.setState({scrolled: true});
        } else if (window.scrollY === 0 && this.state.scrolled) {
            this.setState({scrolled: false});
        }
    }

    onPlayClick = () => {
        // eslint-disable-next-line no-undef
        gtag_report_conversion();
        window.scrollTo({
            top: this.playRef.current.offsetTop,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div
                className={'w-full relative outline-none'}
            >
                <div className={`fixed top-0 left-0 right-0 z-50 ${this.state.scrolled ? 'bg-white/100' : 'bg-white/0'} transition-all duration-300`}>
                    <div className={'container mx-auto px-4'}>
                        <Header scrolled={this.state.scrolled} play={this.onPlayClick}/>
                    </div>
                </div>
                <div
                    style={{
                        backgroundImage: 'url(/assets/images/background.jpg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100vh',
                        width: '100vw'
                    }}
                >
                    <div className={'container mx-auto h-full flex items-center relative'}>
                        <div className={'flex-1 hidden md:block h-full'}></div>
                        <div className={'flex-1 h-full flex flex-col items-center justify-center text-left'}>
                            <div className={'flex flex-col justify-center items-center px-3 md:px-12 space-y-5'}>
                                <div className={''}>
                                    <div className={'text-white/90 text-4xl md:text-5xl font-bold'}>Твори свою історію на найкращому
                                        українському проєкті
                                    </div>
                                </div>
                                <div className={'text-white/60 text-xl'}>
                                    Занурся в атмосферу захоплюючих перестрілок, вечірках на яхті, гонках на суперкарах
                                    та в світ жорстокого бізнесу. Користуйся безмежними можливостями!
                                </div>
                                <div className={'w-full md:flex justify-center md:space-x-3 space-y-3 md:space-y-0'}>
                                    <button
                                        onClick={this.onPlayClick}
                                        className={'w-full md:flex-1 py-6 bg-main-50 flex justify-center space-x-3 items-center hover:scale-105 transition-all duration-300'}>
                                        <div className={'text-white text-xl font-bold uppercase'}>Почати грати</div>
                                        <FontAwesomeIcon className={'text-white text-2xl'} icon={faCirclePlay}/>
                                    </button>
                                    <button
                                        className={'w-full md:flex-1 py-6 bg-neutral-900/90 border border-main-50 flex justify-center items-center hover:scale-105 transition-all duration-300'}>
                                        <div className={'text-white text-xl font-bold uppercase'}>Дізнатись про нас
                                        </div>
                                    </button>

                                </div>
                            </div>
                        </div>

                        <div ref={this.playRef} className={'absolute bottom-10 right-0 md:mr-12 w-full md:w-auto md:mt-0 mt-12'}>
                            <div className={'flex space-x-4 justify-center md:justify-end items-center'}>
                                <div className={'text-white text-xl font-bold uppercase leading-9'}>Приєднуйся</div>
                                <div className={'flex items-center justify-center'}>
                                    <button
                                        onClick={() => {
                                            // eslint-disable-next-line no-undef
                                            gtag_report_conversion();
                                            window.open('https://discord.gg/qxvJYh4H84', '_blank')
                                        }}
                                        className={'h-16 w-48 flex justify-center items-center bg-black hover:bg-ds-50 transition-all duration-300'}>
                                        <div
                                            className={'h-full w-1/2'}
                                            style={{
                                                backgroundImage: 'url(/assets/images/discord-logo-white.png)',
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                        ></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bg-main-50 py-12 w-full'}>
                    <div className={'container md:mx-auto space-y-12 px-4 md:px-12'}>
                        <div className={'w-full text-3xl text-white font-bold text-center uppercase'}>Як почати грати
                        </div>
                        <div className={'w-full md:flex md:space-x-6 space-y-6 md:space-y-0'}>
                            <div
                                className={'flex-1 bg-neutral-900 px-6 pt-6 pb-6 flex flex-col justify-between text-start relative space-y-4'}>
                                <div className={'w-full flex flex-col space-y-4'}>
                                    <div
                                        className={'absolute left-10 -top-5 bg-white rounded-full h-10 aspect-square flex justify-center items-center'}>
                                        <div className={'text-xl font-bold text-black'}>1</div>
                                    </div>

                                    <div className={'text-white font-medium text-xl uppercase'}>Придбайте ліцензійну версію гри
                                    </div>
                                    <div className={'text-white/50 font-normal text-lg'}>Придбати Grand The Auto V можна на офіційних платформах: Steam, Epic Games або Social Club.
                                    </div>
                                </div>

                                <button
                                    onClick={() => {
                                        window.open('https://store.steampowered.com/agecheck/app/271590/', '_blank')
                                    }}
                                    className={'w-full py-3 bg-main-50 hover:bg-white transition-all duration-300 flex justify-center items-center self-end space-x-5 text-white hover:text-black'}>
                                    <div className={'text-lg font-bold uppercase'}>Купити GTA V</div>
                                    <div
                                        className={'w-12 aspect-square'}
                                        style={{
                                            backgroundImage: 'url("/assets/images/gta_logo.png")',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                        }}
                                    ></div>
                                </button>
                            </div>
                            <div
                                className={'flex-1 bg-neutral-900 px-6 pt-6 pb-6 flex flex-col justify-between text-start relative space-y-4'}>
                                <div className={'w-full flex flex-col space-y-4'}>
                                    <div
                                        className={'absolute left-10 -top-5 bg-white rounded-full h-10 aspect-square flex justify-center items-center'}>
                                        <div className={'text-xl font-bold text-black'}>2</div>
                                    </div>

                                    <div className={'text-white font-medium text-xl uppercase'}>Скачайте лаунчер RAGE Multiplayer
                                    </div>
                                    <div className={'text-white/50 font-normal text-lg'}>Завантажте та встановіть
                                        програму.
                                        Вкажіть шлях до папки з грою.
                                    </div>
                                </div>

                                <button
                                    onClick={() => {
                                        window.open('https://cdn.rage.mp/public/files/RAGEMultiplayer_Setup.exe', '_blank')
                                    }}
                                    className={'w-full py-3 bg-main-50 hover:bg-white transition-all duration-300 flex justify-center items-center self-end space-x-5 text-white hover:text-black'}>
                                    <div className={'text-lg font-bold uppercase'}>Завантажити RAGE MP</div>
                                    <div
                                        className={'w-12 aspect-square'}
                                        style={{
                                            backgroundImage: 'url("/assets/images/rage_logo.png")',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                        }}
                                    ></div>
                                </button>
                            </div>
                            <div
                                className={'flex-1 bg-neutral-900 px-6 pt-6 pb-6 flex flex-col justify-between text-start relative space-y-4'}>
                                <div className={'w-full flex flex-col space-y-4'}>
                                    <div
                                        className={'absolute left-10 -top-5 bg-white rounded-full h-10 aspect-square flex justify-center items-center'}>
                                        <div className={'text-xl font-bold text-black'}>3</div>
                                    </div>

                                    <div className={'text-white font-medium text-xl uppercase'}>Знайдіть проєкт BOGEMA
                                        RP у пошуку
                                    </div>
                                    <div className={'text-white/50 font-normal text-lg'}>Або скопіюйте адресу у лаунчер
                                        і
                                        підключіться напряму: <br/><br/>
                                        IP: <b className={'text-white'}>game.bogema.online</b><br/>
                                        PORT: <b className={'text-white'}>22005</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'bg-white py-12 w-full mt-12'}>
                    <div className={'container mx-auto space-y-12 px-12'}>
                        <div className={'w-full text-3xl text-black font-bold text-center uppercase'}>Скріншоти
                        </div>
                        <div className={'w-full flex space-x-6'}>
                            <div className={'text-black text-xl text-center w-full'}>
                                Місто, де мрії стають реальністю. Відкрий для себе нові горизонти на BOGEMA RP
                            </div>
                        </div>

                        <div className={'w-full h-64 md:flex justify-between space-y-6 md:space-y-0 md:space-x-3'}>
                            <div
                                style={{
                                    backgroundImage: 'url("/assets/images/screens/1.jpg")',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                className={'flex-1 h-full bg-black'}></div>
                            <div
                                style={{
                                    backgroundImage: 'url("/assets/images/screens/2.png")',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                className={'flex-1 h-full bg-black'}></div>
                            <div
                                style={{
                                    backgroundImage: 'url("/assets/images/screens/3.jpg")',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                className={'flex-1 h-full bg-black'}></div>
                            <div
                                style={{
                                    backgroundImage: 'url("/assets/images/screens/4.jpg")',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                className={'flex-1 h-full bg-black'}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Main;