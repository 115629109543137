import React, { Component } from 'react';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0,
        };
        this.carouselItems = [
            '/docs/images/carousel/carousel-1.svg',
            '/docs/images/carousel/carousel-2.svg',
            '/docs/images/carousel/carousel-3.svg',
            '/docs/images/carousel/carousel-4.svg',
            '/docs/images/carousel/carousel-5.svg',
        ];
    }

    // Метод для переключения слайда
    goToSlide = (index) => {
        this.setState({ currentSlide: index });
    };

    // Метод для перехода к следующему слайду
    nextSlide = () => {
        this.setState((prevState) => {
            const nextIndex = (prevState.currentSlide + 1) % this.carouselItems.length;
            return { currentSlide: nextIndex };
        });
    };

    // Метод для перехода к предыдущему слайду
    prevSlide = () => {
        this.setState((prevState) => {
            const prevIndex = (prevState.currentSlide - 1 + this.carouselItems.length) % this.carouselItems.length;
            return { currentSlide: prevIndex };
        });
    };

    render() {
        const { currentSlide } = this.state;

        return (
            <div id="default-carousel" className="relative w-full" data-carousel="slide">
                {/* Carousel wrapper */}
                <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
                    {this.carouselItems.map((item, index) => (
                        <div
                            key={index}
                            className={`absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-opacity duration-700 ease-in-out ${
                                index === currentSlide ? 'opacity-100' : 'opacity-0'
                            }`}
                            data-carousel-item
                        >
                            <img src={item} className="w-full" alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </div>

                {/* Slider indicators */}
                <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                    {this.carouselItems.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-blue-500' : 'bg-white'}`}
                            aria-current={index === currentSlide ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                            onClick={() => this.goToSlide(index)}
                        ></button>
                    ))}
                </div>

                {/* Slider controls */}
                <button
                    type="button"
                    className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    onClick={this.prevSlide}
                    data-carousel-prev
                >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
                className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
                </button>

                <button
                    type="button"
                    className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    onClick={this.nextSlide}
                    data-carousel-next
                >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
                className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
            </svg>
            <span className="sr-only">Next</span>
          </span>
                </button>
            </div>
        );
    }
}

export default Carousel;
